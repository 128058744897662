<template>
  <div class="qiancun">
    <Nav></Nav>
    <div class="qiancun-con">
      <div class="title">
        <img src="@/assets/qiancun.png" alt="" />
        <!-- <div class="btn">
          <div class="zhenggao">
            <a href="/activityPage.html?code=165" target="_blank"></a>
          </div>
        </div> -->
      </div>
      <div class="qiancun-title">
        千村美景 >> <span>{{ $route.query.title }}</span>
      </div>
      <div class="content">
        <div class="post" v-for="p in fixtopicList" :key="p.id">
          <div
            class="img"
            :style="{
              backgroundImage: `url(` + p.imgURL + `)`,
            }"
          >
            <a :href="'/post.html?id=' + p.id" target="_blank"></a>
          </div>
        </div>
      </div>
      <div class="pager">
        <el-pagination
          class="pager"
          @current-change="onPageChange"
          :current-page="pager.currentPage"
          :page-size="pager.pageSize"
          :hide-on-single-page="false"
          layout="prev, pager, next, jumper"
          :total="pager.total"
        ></el-pagination>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'
export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      fixtopicList: [], // 栏目的列表
      pager: {
        currentPage: 1,
        total: 1,
        pageSize: 20,
      }, // 分页条件
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      console.log('xujiwu', this.$route.query)
      RD.fixedTopic()
        .id(this.$route.query.id)
        .posts(this.pager.currentPage, 20)
        .then((data) => {
          data.list.forEach((p) => {
            p.imgURL = RD.pic().id(p.frontPicId).mImage_URL()
          })
          thiz.fixtopicList = data.list
          thiz.pager = data.pager
        })
    },
    onPageChange(newPage) {
      this.pager.currentPage = newPage
      this.reload()
    },
  },
}
</script>

<style scoped lang="less">
@media all and (max-width: 800px) {
  .qiancun-con {
    min-height: calc(100vh - 32.7rem);
    position: relative;
    .title {
      width: 750px;
      margin: 20px auto;
      position: relative;
      img {
        object-fit: cover;
        width: 100%;
      }
      .btn {
        position: absolute;
        bottom: 30px;
        right: 50px;
        .zhenggao {
          width: 164px;
          height: 66px;
          line-height: 40px;
          font-size: 24px;
          color: #fff;
          background: no-repeat url(../assets/zhenggaoBtn.png);
          background-size: cover;
          a {
            width: 100%;
            height: 100%;
            display: inline-block;
          }
        }
      }
      .text {
        position: absolute;
        width: 273px;
        right: 100px;
        bottom: 95px;
        div {
          text-align: center;
          font-size: 22px;
          line-height: 30px;
          font-weight: bold;
          text-shadow: 5px 5px 10px yellow;
        }
      }
    }
    .qiancun-title {
      font-size: 1.8rem;
      width: 120rem;
      margin: 0 auto 2rem;
      text-align: left;
      height: 5rem;
      line-height: 5rem;
      span {
        color: red;
      }
    }
    .content {
      width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .post {
        width: 350px;
        height: 229px;
        margin-bottom: 25px;
        .img {
          width: 100%;
          height: 100%;
          background: no-repeat;
          background-size: cover;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }
      }
      // .post:nth-child(1n + 5) {
      //   display: none;
      // }
    }
    .pager {
      width: 120rem;
      margin: 20px auto 0px;
      text-align: right;
    }
  }
}
@media all and (min-width: 801px) {
  .qiancun-con {
    min-height: calc(100vh - 32.7rem);
    position: relative;
    .title {
      width: 120rem;
      margin: 20px auto;
      position: relative;
      img {
        object-fit: cover;
        width: 100%;
      }
      .btn {
        position: absolute;
        bottom: 30px;
        right: 50px;
        .zhenggao {
          width: 164px;
          height: 66px;
          line-height: 40px;
          font-size: 24px;
          color: #fff;
          background: no-repeat url(../assets/zhenggaoBtn.png);
          background-size: cover;
          a {
            width: 100%;
            height: 100%;
            display: inline-block;
          }
        }
      }
      .text {
        position: absolute;
        width: 273px;
        right: 100px;
        bottom: 95px;
        div {
          text-align: center;
          font-size: 22px;
          line-height: 30px;
          font-weight: bold;
          text-shadow: 5px 5px 10px yellow;
        }
      }
    }
    .qiancun-title {
      font-size: 1.8rem;
      width: 120rem;
      margin: 0 auto 2rem;
      text-align: left;
      height: 5rem;
      line-height: 5rem;
      span {
        color: red;
      }
    }
    .content {
      width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .post {
        width: 280px;
        height: 220px;
        margin-bottom: 2rem;
        .img {
          width: 100%;
          height: 100%;
          background: no-repeat;
          background-size: cover;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }
      }
      // .post:nth-child(1n + 5) {
      //   display: none;
      // }
    }
    .pager {
      width: 120rem;
      margin: 20px auto 0px;
      text-align: right;
    }
  }
}
</style>
